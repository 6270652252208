import React from 'react';
import './pageHeader.css';

const PageHeader = (props) => {
	const { title, bgImage, bgImageSrcSet } = props;
	return (
		<>
			<div className='page__header'>
				<div className='page__header__container section__container'>
					<div className='page__header-text'>
						<h2 className='wow fadeInUp' data-wow-delay='0.3s'>
							{title}
						</h2>
					</div>
				</div>
				<div className='page__header-image'>
					<div className='img-filter-gradient'></div>
					<img src={bgImage} alt='headerImage' srcSet={bgImageSrcSet} />
				</div>
			</div>
		</>
	);
};

export default PageHeader;
