import React, { useEffect, useState } from 'react';
import './home.css';
import { Header, ContactForm } from '../../components';
import { mind, heart, body, programs, mindSrcSet, heartSrcSet, bodySrcSet, programsSrcSet, bldg, imgNotAvailable } from './import';
import { GrLocation } from 'react-icons/gr';
import { BiTime, BiMobileVibration } from 'react-icons/bi';
import sanityClient from '../../client';
import imageUrlBuilder from '@sanity/image-url';

import 'react-gallery-carousel/dist/index.css';

const builder = imageUrlBuilder(sanityClient);
const urlFor = (source) => {
	return builder.image(source);
};

const Home = () => {
	const [posts, setPosts] = useState(null);

	useEffect(() => {
		sanityClient
			.fetch(
				`*[_type=='post'] | order(publishedAt desc, _createdAt desc)[0..2]{
													title, 
													slug,
													author,
													shortDescription,
													mainImage{
														asset->{
															_id,
															url
														},
														alt
													},
													body,
													images,
													publishedAt,
													_createdAt
												}`
			)
			.then((data) => {
				setPosts(data);
			})
			.catch(console.error);
	}, []);

	return (
		<div className='home'>
			<Header />

			<div className='section  '>
				<div className='section__container'>
					<div>
						<p className='wow fadeInUp section__titleContent' style={{ display: 'none' }} data-wow-delay='0.3s'>
							Welcome to Tzu Chi Great Love Preschool Philippines <br />
							<span>where curiosity, creativity, and character-building flourish</span>
						</p>
						<div className='row wow fadeInUp welcome__section' data-wow-delay='0.4s'>
							<div className='col welcomeTextContent '>
								<p>
									Master Cheng Yen constantly reiterates that the world&#39;s future lies in children and the children&#39;s future lies in education. Lucy Sprague Mitchell, a
									Developmental Interaction Approach proponent, believes that the opportunity to build a better society lies in education. Anchoring the school’s curriculum to Master
									Cheng Yen’s beliefs and the Developmental Interaction Approach, we strive to put social justice, advocacy, and building an inclusive community in our child-centered
									curriculum. Likewise, moral values and life skills will be practiced in the children’s daily routine to hone their early independence skills.
								</p>
								<br />
								<p>We look forward to welcoming you to our family!</p>
							</div>
							<div className='col welcomeImageContent wow fadeInUp' data-wow-delay='0.5s'>
								{/* <video controls poster={videoCover} playsInline loop muted>
									<source src={video} type='video/mp4' />
									Your browser does not support the video tag.
								</video> */}
								<iframe
									width='560'
									height='315'
									src='https://www.youtube.com/embed/wHG_gsKAc0Y'
									title='Tzu Chi Great Love Preschool Philippines'
									frameBorder='0'
									allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
									allowFullScreen></iframe>

								{/* <iframe src='https://player.vimeo.com/video/747657240?h=b614827019' width='640' height='564' frameBorder='0' allow='autoplay; fullscreen'></iframe> */}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='section__aboutUs'>
				<div>
					<img src={bldg} alt='' />
					<div className='about__gradient'></div>
					<div className='about__textContainer'>
						<div>
							<h2 className='wow fadeInUp section_textContentTitle' data-wow-delay='0.4s'>
								A Progressive School <br />
								for Children Ages 2-4
							</h2>
							<p className='wow fadeInUp' data-wow-delay='0.5s'>
								Tzu Chi Great Love Preschool Philippines is a progressive school for children ages 2-4. Our programs aim to develop young children&#39;s physical, cognitive,
								socio-emotional, character, and life skills and Tzu Chi’s humanistic values through experiential learning in a loving and nurturing environment.
							</p>
							<a href='./about'>
								<button className='btn wow fadeInLeft section__textContentButton' data-wow-delay='0.7s'>
									Learn More
								</button>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className='section'>
				<div className='section__container'>
					<div className='row section__programs'>
						<div className='col programImageContent wow fadeInUp' data-wow-delay='0.8s'>
							<img src={programs} alt='welcomeImage' srcSet={programsSrcSet} />
						</div>
						<div className='col'>
							<div className='programTextContent'>
								<h2 className='section_textContentTitle wow fadeInDown ' data-wow-delay='0.4s'>
									Our Programs
								</h2>
								<p className='wow fadeInUp' data-wow-delay='0.5s'>
									Tzu Chi Great Love Preschool Philippines’ programs support and nurture young children’s social and emotional skills and, at the same time, provide meaningful
									experiences that foster their emergent literacy skills, process skills, language skills, STREAM (science, technology, reading, engineering, arts, mathematics)
									skills, Chinese Mandarin language, approaches to learning, life skills, and character building.
								</p>
								<a href='./programs'>
									<button className='btn wow fadeInLeft section__textContentButton' data-wow-delay='0.7s'>
										Learn More
									</button>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='section bg-gray'>
				<div className='section__container'>
					<div>
						<div className='section__header text__center'>
							<h2 className='section__title wow fadeInUp' data-wow-delay='0.2s'>
								Why Choose Us?
							</h2>
							<div className='section__header-bar wow fadeInLeft' data-wow-delay='0.7s'></div>
							<p className='wow fadeInDown' data-wow-delay='0.8s'>
								We nurture the child’s heart, mind, and body.
							</p>
						</div>
						<div className='card__container'>
							<div className='card wow fadeInUp' data-wow-delay='0.5s'>
								<div className='card__image'>
									<img src={heart} alt='heart' srcSet={heartSrcSet} />
								</div>
								<div className='card__text'>
									<div className='card__title'>Heart</div>
									<div className='card__content'>
										We provide a safe and nurturing environment based on respect and positive regard. The school strives to foster an ethic of social responsibility in each child
										and promote Tzu Chi’s humanistic values as we nurture 21st-century ready learners.
									</div>
								</div>
							</div>
							<div className='card wow fadeInUp' data-wow-delay='.7s'>
								<div className='card__image'>
									<img src={mind} alt='mind' srcSet={mindSrcSet} />
								</div>
								<div className='card__text'>
									<div className='card__title'>Mind</div>
									<div className='card__content'>
										We provide daily opportunities to engage in character building, literacy, life skills, STREAM (science, technology, reading, engineering, arts, mathematics)
										activities, and meaningful experiences that allow children to explore, investigate, ask questions, and reflect upon their experiences. Our child-centered
										curriculum is designed so that play opportunities are child-initiated and teacher- guided.
									</div>
								</div>
							</div>
							<div className='card wow fadeInUp' data-wow-delay='.9s'>
								<div className='card__image'>
									<img src={body} alt='body' srcSet={bodySrcSet} />
								</div>
								<div className='card__text'>
									<div className='card__title'>Body</div>
									<div className='card__content'>
										We provide various activities for children to engage in gross motor and fine motor activities. Sound health habits and promoting self-help skills will be on top
										of our priorities.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{posts?.length > 0 && (
				<div className='section '>
					<div className='section__container'>
						<div className='alignCenter'>
							<div className='section__header text__center'>
								<h2 className='section__title wow fadeInUp' data-wow-delay='0.2s'>
									News & Events
								</h2>
								<div className='section__header-bar wow fadeInLeft' data-wow-delay='0.7s'></div>
								<p className='wow fadeInDown' data-wow-delay='0.8s'>
									Latest Updates, Stories, and Events
								</p>
							</div>
							<div className='card__container'>
								{posts?.map((post, index) => {
									const singleNewsUrl = `/news/article/${post?.slug.current}`;

									return (
										<div className='card' key={index}>
											<div className='card__image'>
												<a href={singleNewsUrl}>
													{post?.mainImage != null ? (
														<img src={urlFor(post?.mainImage).width(500).url()} alt={post?.title} />
													) : (
														<img src={imgNotAvailable} alt='Not Available' />
													)}
												</a>
											</div>
											<div className='card__text'>
												<a href={singleNewsUrl}>
													<div className='card__title'>{post?.title}</div>
												</a>
											</div>
										</div>
									);
								})}
							</div>
							<a href='./news' className='marginAuto'>
								<button className='btn wow fadeInLeft section__button' data-wow-delay='0.7s'>
									See More News
								</button>
							</a>
						</div>
					</div>
				</div>
			)}

			<div className='section bg-green'>
				<div className='section__container contact__formContainer'>
					<div>
						<div className='section__header text__center'>
							<h2 className='section__title wow fadeInUp' data-wow-delay='0.2s'>
								Contact Us
							</h2>
							<div className='section__header-bar wow fadeInLeft' data-wow-delay='0.7s'></div>
							{/* <p className='wow fadeInDown' data-wow-delay='0.8s'>
							Get in touch with us
						</p> */}
						</div>
						<div className='row'>
							<div className='col'>
								<ContactForm />
							</div>
							<div className='col'>
								<div className='contact__form wow fadeInUp' data-wow-delay='0.4s'>
									<h3 className=' wow fadeInUp' data-wow-delay='0.4s'>
										Contact Info
									</h3>
									<div className='contact__infoGroup'>
										<div className='contact__infoGroupItem wow fadeInUp' data-wow-delay='0.6s'>
											<div className='contact__infoGroupItemIcon'>
												<GrLocation />
											</div>
											<div className='contact__infoGroupItemText'>
												<div className='contact__infoGroupItemTextTitle'>Location</div>
												<div className='contact__infoGroupItemTextDetails'>76 Cordillera Street corner Agno Street, Brgy. Doña Josefa, Quezon City</div>
											</div>
										</div>
										<div className='contact__infoGroupItem wow fadeInUp' data-wow-delay='0.8s'>
											<div className='contact__infoGroupItemIcon'>
												<BiTime />
											</div>
											<div className='contact__infoGroupItemText'>
												<div className='contact__infoGroupItemTextTitle'>Opening Hours</div>
												<div className='contact__infoGroupItemTextDetails'>
													Monday – Friday <br />
													7:00 AM - 4:00 PM
												</div>
											</div>
										</div>
										<div className='contact__infoGroupItem wow fadeInUp' data-wow-delay='1s'>
											<div className='contact__infoGroupItemIcon'>
												<BiMobileVibration />
											</div>
											<div className='contact__infoGroupItemText'>
												<div className='contact__infoGroupItemTextTitle'>Contact Us</div>
												<div className='contact__infoGroupItemTextDetails'>
													+63 969 472-4048
													<br />
													<a href='mailto:#' className='link-text'>
														tcglpreschool@tzuchi.org.ph
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='section bg-blue'>
				<div className='section__container'>
					<div className='quote wow fadeInUp' data-wow-delay='0.3s'>
						<p className='quote__text'>"We see in education the opportunity to build a better society."</p>
						<p className='quote__subtext'>Lucy Sprague Mitchell</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
