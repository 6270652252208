import React from 'react';
import './curriculum.css';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import PageHeader from '../../components/pageHeader/PageHeader';
import {
	literatureBased,
	renWen,
	chinese,
	developmental,
	characterBuilding,
	pageHeaderImage,
	pgHeaderImgSrcSet,
	developmentalSrcSet,
	chineseSrcSet,
	characterBuildingSrcSet,
	literatureBasedSrcSet,
	renWenSrcSet,
	creative,
	creativeSrcSet,
	lifeSkillsSrcSet,
	lifeSkills
} from './import';

const Curriculum = () => {
	const pageTitle = 'Curriculum';

	return (
		<>
			<PageHeader title={pageTitle} bgImage={pageHeaderImage} bgImageSrcSet={pgHeaderImgSrcSet} />
			<Breadcrumbs page={pageTitle} />
			<div className='section programs__section  '>
				<div className='section__container'>
					<div className='section__content align-items-top'>
						<img className='wow fadeInUp' src={developmental} srcSet={developmentalSrcSet} alt='Enrollment' data-wow-delay='.4s' />
						<div className='section__contentText wow fadeInUp' data-wow-delay='.6s'>
							<h3>
								<span>CURRICULUM HIGHLIGHTS</span>Developmental Interaction Approach
							</h3>
							<p>
								The Developmental Interaction Approach recognizes that all individuals learn best when they are actively engaged with materials, ideas, and people. It is based on the
								education of the whole child with a focus on a child-centered curriculum. It uses interdisciplinary approaches to academic disciplines and emphasizes children&#39;s
								active engagement in connecting their learning experiences and their home or personal life experiences through child-initiated and teacher-guided play.
							</p>
							<p>
								Lucy Sprague Mitchell, the proponent of the Developmental Interaction Approach, believes that social justice, advocacy, and building an inclusive community are at the
								core of this approach. We strive for young learners to understand themselves more deeply, make sense of the world around them, and have the courage to think
								independently and work collaboratively with others. Our educators will observe the children closely, engage in their world by preparing meaningful learning
								opportunities, and hear the children&#39;s voices. Through thoughtful engagement with teachers, peers, and their environments, students build vital critical thinking
								and the academic and professional skills they will need to thrive in school settings and life.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className='section programs__section bg-gray '>
				<div className='section__container'>
					<div className='section__content '>
						<div className='section__contentText wow fadeInUp' data-wow-delay='.6s'>
							<h3>
								<span>CURRICULUM HIGHLIGHTS</span>Chinese Mandarin Language
							</h3>
							<p>
								The Chinese Language Program is integrated into the regular preschool program. Basic conversational skills, character recognition, and writing will be embedded in the
								program. Students will also be exposed to Chinese culture.
							</p>
							<p>
								<abbr title='Science, Technology, Reading, Engineering, Arts, Mathematics'>STREAM </abbr>Education is a learning journey involving curiosity, exploration, in-depth
								inquiry, investigation, and discovery. It integrates content learning (what to learn) and process learning (how to learn). Children learn best through “learning by
								doing”; learning cannot be separated from the children's experiences. As children's understandings become relevant, they can freely manipulate, engage, and solve
								problems in a safe and engaging setting.
							</p>
						</div>
						<img className='wow fadeInUp' src={chinese} srcSet={chineseSrcSet} alt='Enrollment' data-wow-delay='.4s' />
					</div>
				</div>
			</div>
			<div className='section programs__section  '>
				<div className='section__container'>
					<div className='section__content '>
						<img className='wow fadeInUp' src={literatureBased} srcSet={literatureBasedSrcSet} alt='LiteratureBasedImage' data-wow-delay='.4s' />
						<div className='section__contentText wow fadeInUp' data-wow-delay='.6s'>
							<h3>
								<span>CURRICULUM HIGHLIGHTS</span>Literature-Based Instruction
							</h3>
							<p>
								Striving to have an interest in reading is one of the goals of Tzu Chi Great Love Preschool Philippines. Children will have opportunities to learn about books and
								discover their interest in and love for reading. The teachers plan opportunities for children to engage in meaningful experiences that support the themes and concepts
								introduced in the storybooks.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className='section programs__section bg-gray '>
				<div className='section__container'>
					<div className='section__content align-items-top '>
						<div className='section__contentText wow fadeInUp' data-wow-delay='.6s'>
							<h3>
								<span>CURRICULUM HIGHLIGHTS</span>Creative Self-Expression & Critical Thinking Skills
							</h3>
							<p>
								Tzu Chi Great Love Preschool Philippines values children’s individuality and encourages creativity through creative self-expression. They will be given various
								opportunities to imagine and express themselves through process-oriented or open-ended art activities, including play, drama, music, and dance. They are surrounded by
								adults in the school who are nonjudgmental about their choices and are always focused on the process of creativity rather than the product.
							</p>
							<p>
								Likewise, we support the development of thinking skills in children more than rote memorization. Therefore, a wide range of opportunities are provided to children to
								plan, explore materials and objects, observe, and ask “what,” “why,” and “how,” or inquire meaningfully about their areas of interest, experiment or try out their new
								ideas or new ways of thinking and problem solving, and reflect upon their experiences.
							</p>
						</div>
						<img className='wow fadeInUp' src={creative} srcSet={creativeSrcSet} alt='Enrollment' data-wow-delay='.4s' />
					</div>
				</div>
			</div>
			<div className='section programs__section  '>
				<div className='section__container'>
					<div className='section__content '>
						<img className='wow fadeInUp' src={characterBuilding} srcSet={characterBuildingSrcSet} alt='Enrollment' data-wow-delay='.4s' />
						<div className='section__contentText wow fadeInUp' data-wow-delay='.6s'>
							<h3>
								<span>CURRICULUM HIGHLIGHTS</span>Character Building
							</h3>
							<p>
								Character building is an essential foundation for a better future. In Tzu Chi Great Love Preschool Philippines, we will foster positive character traits such as respect
								or filial piety, responsibility, gratitude, compassion, contentment, and environmental protection. Children will be exposed to the world around them at a very young age
								as educators create meaningful connections for the children to gain a sense of social awareness and social connectedness.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className='section programs__section bg-gray '>
				<div className='section__container'>
					<div className='section__content align-items-top '>
						<div className='section__contentText wow fadeInUp' data-wow-delay='.6s'>
							<h3>
								<span>CURRICULUM HIGHLIGHTS</span>Life Skills
							</h3>
							<p>
								Various life skills help a child have a brighter and more successful future. In our preschool, we give importance to teaching them life skills such as being responsible
								for their own space, attending to their own needs and the needs of others, and giving him or them a voice in school. As we develop these life skills, children will gain
								self-confidence, help them be responsible and empathetic towards others, and adapt to life changes as they face the world.
							</p>
						</div>
						<img className='wow fadeInUp' src={lifeSkills} srcSet={lifeSkillsSrcSet} alt='Enrollment' data-wow-delay='.4s' />
					</div>
				</div>
			</div>
			<div className='section programs__section  '>
				<div className='section__container'>
					<div className='section__content '>
						<img className='wow fadeInUp' src={renWen} srcSet={renWenSrcSet} alt='Enrollment' data-wow-delay='.4s' />
						<div className='section__contentText wow fadeInUp' data-wow-delay='.6s'>
							<h3>
								<span>CURRICULUM HIGHLIGHTS</span>Ren Wen Education (Humanistic Education)
							</h3>
							<p>
								Ren Wen Education is one of the top priorities of Tzu Chi Great Love Preschool Philippines. Tzu Chi’s three pillars of gratitude, respect, and love will be honed
								through daily exposure to life experiences and learning opportunities. By embedding humanistic education and implementing Jing-Si Aphorisms in our curriculum, we aim to
								have kind, polite, and loving citizens.
							</p>
							<p>Exposure to humanistic culture (such as tea ceremony, flower arrangement, calligraphy, and sign language) will also be included in the curriculum.</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Curriculum;
