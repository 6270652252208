import React from 'react';
import './header.css';
import '../../assets/css/my-custom-slick.css';
import Slider from 'react-slick';
import { slide1, slide2, slide3, slide1SrcSet, slide2SrcSet, slide3SrcSet } from './import';

const Header = () => {
	const settings = {
		dots: true,
		infinite: true,
		fade: true,
		speed: 2500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 4000,
		pauseOnHover: false,
		swipeToSlide: true
	};
	return (
		<header className='header' id='home'>
			<Slider className='header__slick' {...settings}>
				<div>
					<div className='slick__group-text '>
						<div className='slick__text container '>
							<div className='slick__text-group wow fadeInUp' data-wow-delay='0.2s'>
								<h4>Welcome to</h4>
								<h2>Tzu Chi Great Love</h2>
								<h2>Preschool Philippines</h2>
								<span>where curiosity, creativity, and character-building flourish</span>
							</div>
							<a href='/admissions'>
								<button className='btn btn-lg wow fadeInLeft' data-wow-delay='0.5s' type='button'>
									ENROLL NOW &#10141;
								</button>
							</a>
						</div>
					</div>
					<div className='img-filter-gradient'></div>
					<img src={slide2} alt='child' srcSet={slide2SrcSet} />
				</div>
				<div>
					<div className='slick__group-text '>
						<div className='slick__text container '>
							<div className='slick__text-group wow fadeInUp' data-wow-delay='0.2s'>
								<h4>Welcome to</h4>
								<h2>Tzu Chi Great Love</h2>
								<h2>Preschool Philippines</h2>
								<span>where curiosity, creativity, and character-building flourish</span>
							</div>
							<a href='/admissions'>
								<button className='btn btn-lg wow fadeInLeft' data-wow-delay='0.5s' type='button'>
									ENROLL NOW &#10141;
								</button>
							</a>
						</div>
					</div>
					<div className='img-filter-gradient'></div>
					<img src={slide3} alt='tree' srcSet={slide3SrcSet} />
				</div>
				<div>
					<div className='slick__group-text '>
						<div className='slick__text container '>
							<div className='slick__text-group wow fadeInUp' data-wow-delay='0.2s'>
								<h4>Welcome to</h4>
								<h2>Tzu Chi Great Love</h2>
								<h2>Preschool Philippines</h2>
								<span>where curiosity, creativity, and character-building flourish</span>
							</div>
							<a href='/admissions'>
								<button className='btn btn-lg wow fadeInLeft' data-wow-delay='0.5s' type='button'>
									ENROLL NOW &#10141;
								</button>
							</a>
						</div>
					</div>
					<div className='img-filter-gradient'></div>
					<img src={slide1} alt='classroom' srcSet={slide1SrcSet} />
				</div>
			</Slider>
		</header>
	);
};

export default Header;
