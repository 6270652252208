import React from 'react';
import './footer.css';
import { ImFacebook, ImTwitter, ImInstagram, ImYoutube } from 'react-icons/im';

const Footer = () => {
	return (
		<footer className='footer'>
			<div className='footer__container container'>
				<div className='footer__row'>
					<div className='footer__col'>
						<div className='footer__contact'>
							<h4>CONTACT US</h4>
							<p className='footer__contact-number'>+63 969 472-4048</p>
							<p className='footer__contact-address'>
								76 Cordillera Street corner Agno Street,
								<br />
								Brgy. Doña Josefa, Quezon City
							</p>
						</div>
					</div>
					<div className='footer__col footer__col-sm'>
						<ul className='footer__links'>
							<li>
								<a href='/'>Home</a>
							</li>
							<li>
								<a href='/about'>About</a>
							</li>
							<li>
								<a href='/curriculum'>Curriculum</a>
							</li>
							<li>
								<a href='/programs'>Programs</a>
							</li>
							<li>
								<a href='/admissions'>Admissions</a>
							</li>
							<li>
								<a href='/news'>News</a>
							</li>
							<li>
								<a href='/contact'>Contact Us</a>
							</li>
						</ul>
					</div>
				</div>
				<div className='footer__row'>
					<div className='footer__col'>
						<div className='footer__about'>
							Tzu Chi Great Love Preschool Philippines is a progressive school for children ages 2-4. Our programs aim to develop young children's physical, cognitive, socio-emotional,
							life skills, character, and Tzu Chi’s humanistic values through experiential learning in a loving and nurturing environment.
						</div>
					</div>
					<div className='footer__col footer__col-sm'>
						<ul className='footer__socials'>
							<li>
								<a target='__blank' href='https://www.facebook.com/TzuChiPH'>
									<ImFacebook />
								</a>
							</li>
							<li>
								<a target='__blank' href='https://twitter.com/TzuChiPH'>
									<ImTwitter />
								</a>
							</li>
							<li>
								<a target='__blank' href='https://www.instagram.com/tzuchiph'>
									<ImInstagram />
								</a>
							</li>
							<li>
								<a target='__blank' href='https://www.youtube.com/user/tzuchiphil'>
									<ImYoutube />
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div className='footer__row'>
					<div className='footer__col'>
						<div className='footer__copyright'>Copyright © 2022 Tzu Chi Great Love Preschool.</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
