const slide1 = 'https://ik.imagekit.io/59olwmt4e/home-hero-classroom.jpg';
const slide2 = 'https://ik.imagekit.io/59olwmt4e/home-hero-child.jpg';
const slide3 = 'https://ik.imagekit.io/59olwmt4e/home-hero-tree.jpg';

const slide1SrcSet =
	'https://ik.imagekit.io/59olwmt4e/tr:w-400/home-hero-classroom.jpg 400w, https://ik.imagekit.io/59olwmt4e/tr:w-600/home-hero-classroom.jpg 600w, https://ik.imagekit.io/59olwmt4e/tr:w-800/home-hero-classroom.jpg 800w, https://ik.imagekit.io/59olwmt4e/tr:w-1200/home-hero-classroom.jpg 1200w, https://ik.imagekit.io/59olwmt4e/home-hero-classroom.jpg';

const slide2SrcSet =
	'https://ik.imagekit.io/59olwmt4e/tr:w-400/home-hero-child.jpg 400w, https://ik.imagekit.io/59olwmt4e/tr:w-600/home-hero-child.jpg 600w, https://ik.imagekit.io/59olwmt4e/tr:w-800/home-hero-child.jpg 800w, https://ik.imagekit.io/59olwmt4e/tr:w-1200/home-hero-child.jpg 1200w, https://ik.imagekit.io/59olwmt4e/home-hero-child.jpg';
const slide3SrcSet =
	'https://ik.imagekit.io/59olwmt4e/tr:w-400/home-hero-tree.jpg 400w, https://ik.imagekit.io/59olwmt4e/tr:w-600/home-hero-tree.jpg 600w, https://ik.imagekit.io/59olwmt4e/tr:w-800/home-hero-tree.jpg 800w, https://ik.imagekit.io/59olwmt4e/tr:w-1200/home-hero-tree.jpg 1200w, https://ik.imagekit.io/59olwmt4e/home-hero-tree.jpg';
export { slide3, slide1, slide2, slide1SrcSet, slide2SrcSet, slide3SrcSet };
