import React from 'react';
import './breadcrumbs.css';
import { FaGreaterThan } from 'react-icons/fa';

const Breadcrumbs = (props) => {
	const { page } = props;
	return (
		<>
			<div className='breadcrumbs__container'>
				<ul className='breadcrumbs'>
					<li>
						<a href='/'>
							<span>Home</span>
						</a>
					</li>
					<FaGreaterThan />
					<li>
						<span>{page}</span>
					</li>
				</ul>
			</div>
		</>
	);
};

export default Breadcrumbs;
