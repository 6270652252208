import React, { useEffect } from 'react';
import { Navbar, Footer } from '../components';
import { Outlet } from 'react-router-dom';

const Layout = () => {
	useEffect(() => {
		if (window.location.pathname.indexOf('/news/article/') === 0) {
			document.querySelector('.navbar').classList.add('is__sticky');
		}

		document.addEventListener('scroll', () => {
			let scrollTop = window.pageYOffset; // || document.documentElement.scrollTop;

			if (window.location.pathname.indexOf('/news/article/') === 0) {
				document.querySelector('.navbar').classList.add('is__sticky');
			} else {
				if (scrollTop > 100) {
					document.querySelector('.navbar').classList.add('is__sticky');
					//document.querySelector('.go-to-top-btn').classList.add('fade-in-bottom');
					//document.querySelector('.go-to-top-btn').classList.remove('fade-out-bottom');
				} else {
					document.querySelector('.navbar').classList.remove('is__sticky');
					//document.querySelector('.go-to-top-btn').classList.add('fade-out-bottom');
					//document.querySelector('.go-to-top-btn').classList.remove('fade-in-bottom');
				}
			}
		});
	}, []);

	return (
		<div>
			<Navbar />
			<main>
				<Outlet />
			</main>
			<Footer />
			{/*<GoToTop /> */}
		</div>
	);
};

export default Layout;
