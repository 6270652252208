import React from 'react';
import './programs.css';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import { FaLanguage, FaHandsHelping, FaChild } from 'react-icons/fa';
import { GiMaterialsScience } from 'react-icons/gi';
import { GoBook } from 'react-icons/go';
import PageHeader from '../../components/pageHeader/PageHeader';
import { pageHeaderImage, pgHeaderImgSrcSet, childWithTeacher } from './import';
import Slider from 'react-slick';

const Programs = () => {
	const pageTitle = 'Programs';
	const settings = {
		dots: true,
		infinite: true,
		fade: false,
		speed: 2500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		pauseOnHover: true,
		swipeToSlide: true
	};
	return (
		<>
			<PageHeader title={pageTitle} bgImage={pageHeaderImage} bgImageSrcSet={pgHeaderImgSrcSet} />
			<Breadcrumbs page={pageTitle} />
			<div className='section programs__section '>
				<div className='section__container'>
					<div className='section__content'>
						<img className='wow fadeInUp' src={childWithTeacher} alt='Enrollment' data-wow-delay='.4s' />
						<div className='section__contentText wow fadeInUp' data-wow-delay='.6s'>
							<h3>Our Programs</h3>
							<p>
								Tzu Chi Great Love Preschool Philippines’ programs support and nurture young children’s social and emotional skills and, at the same time, provide meaningful
								experiences that foster their emergent literacy skills, process skills, language skills, STREAM (science, technology, reading, engineering, arts, mathematics) skills,
								Chinese Mandarin language, approaches to learning, life skills, and character building.
							</p>
							<ul>
								<li>Nursery Program</li>
								<li>Pre-K Program</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div className='section programs__section bg-gray'>
				<div className='section__container'>
					<div className='section__content alignTop wow fadeInUp' data-wow-delay='.4s'>
						<h3>Nursery Program</h3>
						<div className='section__contentText '>
							<p>
								Our Nursery Program helps your child transition from a home environment to a new community of learners and educators. As young children transition to this new phase of
								their life, we emphasize students’ building of a sense of self and purpose in the group in a diverse environment.
							</p>
							<p>
								In our classroom, children learn by doing, seeing, discovering, experiencing, feeling, and making intelligent choices in their daily routine. Learning centers are
								provided in our classroom targeted to develop the necessary developmental domain as they move on in their life. Our curriculum will ensure a connection to the outside
								world as we take trips in the neighborhood, observe nature, take photos, experience nature, and return to the classroom to extend the activity.
							</p>{' '}
							<p>
								In response to supporting the holistic development of this age group, experiences that promote physical health and well-being, humanistic culture, character building,
								life skills, and positive approaches to learning are also integrated into the curriculum.
							</p>
						</div>
					</div>
				</div>
			</div>

			<Slider className='programs__slider' {...settings}>
				<div className='section programs__section '>
					<div className='section__container'>
						<div className='section__content '>
							<FaChild />
							<div className='section__contentText '>
								<h3>
									<span>LEARNING ACTIVITIES</span>Social-Emotional
								</h3>
								<p>
									At this stage, students are increasingly getting more aware of their sense of self, managing their emotions, and caring for one’s self. Socially, children at this
									stage are already learning to play with friends. At this level, we aim to continually develop their sense of self and the group&#39;s purpose through teacher-guided
									and child-initiated play activities.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='section programs__section '>
					<div className='section__container'>
						<div className='section__content '>
							<GiMaterialsScience />
							<div className='section__contentText '>
								<h3>
									<span>LEARNING ACTIVITIES</span>Science, Technology, Reading, Engineering, <br />
									Arts, and Mathematics
								</h3>
								<p>
									STREAM is an educational discipline that aims to develop children’s love of science, technology, reading, engineering, arts, and mathematics. Our scientists in the
									classroom are encouraged to ask questions, probe, make predictions, test hypotheses, and discover the answers to complex questions. Through the process, students
									will have a strong foundation in basic numeracy skills, which include: rote counting, 1:1 correspondence, patterns, graphing, measurement, geometry, and colors.
									Children’s interest in letter recognition, phonemic awareness, writing, speaking, listening, and reading skills will also be enhanced as they continue to discover
									the world around them. Hands-on experiments will further strengthen children’s curiosity, leading them to be active inquirers.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='section programs__section '>
					<div className='section__container'>
						<div className='section__content '>
							<GoBook />
							<div className='section__contentText '>
								<h3>
									<span>LEARNING ACTIVITIES</span>Approaches to Learning
								</h3>
								<p>
									As children learn to explore the world around them, it is crucial to establish positive approaches to learning to achieve a lifelong love for learning. In this
									stage, children’s curiosity, eagerness to learn, persistence, and attention will be heightened through exposure to various learning opportunities that encourage
									them to learn and do more.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='section programs__section '>
					<div className='section__container'>
						<div className='section__content '>
							<FaLanguage />
							<div className='section__contentText '>
								<h3>
									<span>LEARNING ACTIVITIES</span>Chinese Language
								</h3>
								<p>
									Nursery students in Tzu Chi Great Love Preschool Philippines will be exposed to the Chinese language through authentic day-to-day experiences. At this level, we
									strive to have a strong foundation of receptive and expressive skills in basic conversation and essential items they see every day. Towards the end of the program,
									we will start introducing primary characters for word recognition and reading.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='section programs__section '>
					<div className='section__container'>
						<div className='section__content '>
							<FaHandsHelping />
							<div className='section__contentText '>
								<h3>
									<span>LEARNING ACTIVITIES</span>Character Building
								</h3>
								<p>
									As children in this stage learn to make friends and connect to other people outside their family, they will also be exposed to various character-building activities
									that will make them good citizens of the world. The foundations of ingraining the importance of reflecting ethical values will be practiced as early as now.
								</p>
							</div>
						</div>
					</div>
				</div>
			</Slider>

			<div className='section programs__section bg-gray'>
				<div className='section__container'>
					<div className='section__content alignTop  wow fadeInUp' data-wow-delay='.4s'>
						<h3>Pre-K Program</h3>
						<div className='section__contentText'>
							<p>
								Social Studies is one of the core focuses of the Developmental Interaction Approach. Thus, children in the Pre-K Program are given job charts to encourage them to be
								active participants in a democratic society. As they develop their sense of identity, they also learn that it is equally important to make friends and accept the
								differences of each student. Family and community engagement will be at the core of the program.
							</p>
							<p>
								From transitioning from home to school environment, from learning to manage their own emotions to exploring their immediate environment, children in the Pre-K Program
								will eventually gain a sense of autonomy and ownership of their environment and take on the responsibility of caring for themselves in the classroom.
							</p>{' '}
							<p>
								The children will be provided with a broad range of educational experiences that promote readiness, academics, and motor skills for this level. Activities and learning
								opportunities will be done in meaningful contexts. Child-initiated and teacher-guided play will still be the primary way of acquiring knowledge, and assessment will be
								done in a formative and systematic way.
							</p>
							<p>Ultimately, our Pre-K students will be fully prepared by the end of the program as they transition in preparation for the Kindergarten level.</p>
						</div>
					</div>
				</div>
			</div>

			<Slider className='programs__slider' {...settings}>
				<div className='section programs__section '>
					<div className='section__container'>
						<div className='section__content '>
							<FaChild />
							<div className='section__contentText '>
								<h3>
									<span>LEARNING ACTIVITIES</span>Social-Emotional
								</h3>
								<p>
									Students at this stage are more aware of their societal role. Now that students have already gained control of their emotions and sense of self, group activities
									that lead to overall improvement are encouraged—resulting in resilient 21st-century learners.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='section programs__section '>
					<div className='section__container'>
						<div className='section__content '>
							<GiMaterialsScience />
							<div className='section__contentText '>
								<h3>
									<span>LEARNING ACTIVITIES</span>Science, Technology, Reading, Engineering, <br />
									Arts, and Mathematics
								</h3>
								<p>
									STREAM learning empowers teachers through project-based learning that captures the six disciplines— science, technology, reading, engineering, arts, and
									mathematics. Themes like nature, animals, life cycles, and body systems are among our topics. As our little inquirers are active in making meaning around them,
									teachers will be there to guide them in gathering data, interpreting, testing, and finally reaching an ultimate answer. Likewise, exposure to rhymes, alliteration,
									and more vocabulary will help our learners develop their reading skills.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='section programs__section '>
					<div className='section__container'>
						<div className='section__content '>
							<GoBook />
							<div className='section__contentText '>
								<h3>
									<span>LEARNING ACTIVITIES</span>Approaches to Learning
								</h3>
								<p>
									This domain is essential in school readiness and transition to Kindergarten. Students will continually be given challenging yet achievable activities to hone their
									positive approaches to learning. Activities will push them to focus more constantly, persist, be eager to problem solve, and achieve the desired outcome.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='section programs__section '>
					<div className='section__container'>
						<div className='section__content '>
							<FaLanguage />
							<div className='section__contentText '>
								<h3>
									<span>LEARNING ACTIVITIES</span>Chinese Language
								</h3>
								<p>
									Students in this level will be exposed to thematic learning themes such as family, neighborhood, and the world. At this stage, the increased practice of the Chinese
									language in oral and written forms is encouraged. Towards the end of the program, students will be able to read, write, understand, and speak primary Chinese
									characters.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='section programs__section '>
					<div className='section__container'>
						<div className='section__content '>
							<FaHandsHelping />
							<div className='section__contentText '>
								<h3>
									<span>LEARNING ACTIVITIES</span>Character Building
								</h3>
								<p>
									Learning how to cooperate with others, being forgiving, having a grateful heart, being respectful, loving all living beings, and loving the environment are among
									the characteristics we aim to hone in our students. Character building is essential in and out of the school environment, as it helps children thrive in diverse
									communities.
								</p>
							</div>
						</div>
					</div>
				</div>
			</Slider>
		</>
	);
};

export default Programs;
