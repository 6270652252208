import React, { useRef, useState } from 'react';
import './contactForm.css';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import toast, { Toaster } from 'react-hot-toast';

const ContactForm = () => {
	const form = useRef();
	const recaptchaRef = useRef();
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');

	const sendEmail = (e) => {
		e.preventDefault();
		emailjs.sendForm('service_ogyc4m4', 'template_hv8ovgr', form.current, 'Gx63O9zhfTp8kyLMS').then(
			(result) => {
				console.log(result.text);

				setName('');
				setEmail('');
				setMessage('');
				toast.success("Thanks for reaching out. We'll get back to you soon!", { duration: 2500, style: { background: '#5a9370', color: '#fff' } });
				recaptchaRef.current.reset();
			},
			(error) => {
				toast.error('Please confirm that you are not a robot.', { style: { background: '#c86e6e', color: '#fff' } });
				console.log(error.text);
			}
		);
	};

	return (
		<>
			<form className='contact__form' ref={form} onSubmit={sendEmail}>
				<h3 className=' wow fadeInUp' data-wow-delay='0.4s'>
					Send Us a Message
				</h3>
				<div className='form__group wow fadeInUp' data-wow-delay='0.5s'>
					<input type='text' name='name' placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} className='form-control input-field' required />
					<input type='email' name='email' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} className='form-control input-field' required />
					<textarea name='message' placeholder='Message' value={message} onChange={(e) => setMessage(e.target.value)} className='form-control input-field' rows='6' required />
					<div className='button__recaptcha__container'>
						<button type='submit' className='btn wow fadeInLeft section__textContentButton ' data-wow-delay='0.7s'>
							Submit
						</button>
						<ReCAPTCHA className='recaptcha  wow fadeInDown' ref={recaptchaRef} sitekey='6LeQf5MhAAAAAEVERkF5OpPPHTQtKD3f-FKyjk4w' data-wow-delay='0.7s' />
					</div>
				</div>
			</form>
			<Toaster position='top-center' reverseOrder={false} />
		</>
	);
};

export default ContactForm;
