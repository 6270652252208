//literatureBasedliteratureBasedimport literatureBased from '../../assets/images/literature-based.jpg';
//import renWen from '../../assets/images/renwen.jpg';
//import chinese from '../../assets/images/chinese.jpg';
//import developmental from '../../assets/images/developmental.jpg';
//import characterBuilding from '../../assets/images/character-building.jpg';

const pageHeaderImage = 'https://ik.imagekit.io/59olwmt4e/curriculum-hero-toys.jpg';
const pgHeaderImgSrcSet =
	'https://ik.imagekit.io/59olwmt4e/tr:w-400/curriculum-hero-toys.jpg 400w, https://ik.imagekit.io/59olwmt4e/tr:w-600/curriculum-hero-toys.jpg 600w, https://ik.imagekit.io/59olwmt4e/tr:w-900/curriculum-hero-toys.jpg 900w, https://ik.imagekit.io/59olwmt4e/curriculum-hero-toys.jpg';

const developmental = 'https://ik.imagekit.io/59olwmt4e/curriculum-developmental-interaction.jpg';
const developmentalSrcSet =
	'https://ik.imagekit.io/59olwmt4e/tr:w-300/curriculum-developmental-interaction.jpg 300w, https://ik.imagekit.io/59olwmt4e/tr:w-400/curriculum-developmental-interaction.jpg 400w,  https://ik.imagekit.io/59olwmt4e/tr:w-800/curriculum-developmental-interaction.jpg';

const chinese = 'https://ik.imagekit.io/59olwmt4e/curriculum-chinese.jpg';
const chineseSrcSet =
	'https://ik.imagekit.io/59olwmt4e/tr:w-300/curriculum-chinese.jpg 300w, https://ik.imagekit.io/59olwmt4e/tr:w-400/curriculum-chinese.jpg 400w,  https://ik.imagekit.io/59olwmt4e/tr:w-800/curriculum-chinese.jpg';

const literatureBased = 'https://ik.imagekit.io/59olwmt4e/curriculum-literature-based.jpg';
const literatureBasedSrcSet =
	'https://ik.imagekit.io/59olwmt4e/tr:w-300/curriculum-literature-based.jpg 300w, https://ik.imagekit.io/59olwmt4e/tr:w-400/curriculum-literature-based.jpg 400w,  https://ik.imagekit.io/59olwmt4e/tr:w-800/curriculum-literature-based.jpg';

const characterBuilding = 'https://ik.imagekit.io/59olwmt4e/curriculum-character-building.jpg';
const characterBuildingSrcSet =
	'https://ik.imagekit.io/59olwmt4e/tr:w-300/curriculum-character-building.jpg 300w, https://ik.imagekit.io/59olwmt4e/tr:w-400/curriculum-character-building.jpg 400w,  https://ik.imagekit.io/59olwmt4e/tr:w-800/curriculum-character-building.jpg';

const renWen = 'https://ik.imagekit.io/59olwmt4e/curriculum-renwen.jpg';
const renWenSrcSet =
	'https://ik.imagekit.io/59olwmt4e/tr:w-300/curriculum-renwen.jpg 300w, https://ik.imagekit.io/59olwmt4e/tr:w-400/curriculum-renwen.jpg 400w,  https://ik.imagekit.io/59olwmt4e/tr:w-800/curriculum-renwen.jpg';

const creative = 'https://ik.imagekit.io/59olwmt4e/curriculum-creative-self.jpg';
const creativeSrcSet =
	'https://ik.imagekit.io/59olwmt4e/tr:w-300/curriculum-creative-self.jpg 300w, https://ik.imagekit.io/59olwmt4e/tr:w-400/curriculum-creative-self.jpg 400w,  https://ik.imagekit.io/59olwmt4e/tr:w-800/curriculum-creative-self.jpg';

const lifeSkills = 'https://ik.imagekit.io/59olwmt4e/curriculum-life-skills.jpg';
const lifeSkillsSrcSet =
	'https://ik.imagekit.io/59olwmt4e/tr:w-300/curriculum-life-skills.jpg 300w, https://ik.imagekit.io/59olwmt4e/tr:w-400/curriculum-life-skills.jpg 400w,  https://ik.imagekit.io/59olwmt4e/tr:w-800/curriculum-life-skills.jpg';

export {
	literatureBased,
	renWen,
	chinese,
	developmental,
	characterBuilding,
	pageHeaderImage,
	pgHeaderImgSrcSet,
	developmentalSrcSet,
	chineseSrcSet,
	literatureBasedSrcSet,
	characterBuildingSrcSet,
	renWenSrcSet,
	creative,
	creativeSrcSet,
	lifeSkillsSrcSet,
	lifeSkills
};
