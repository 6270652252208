import React from 'react';
import './admissions.css';
import PageHeader from '../../components/pageHeader/PageHeader';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';

const Admissions = () => {
	const pageTitle = 'Admissions';
	const pageHeaderImage = 'https://ik.imagekit.io/59olwmt4e/admissions-hero-playground.jpg';
	const pgHeaderImgSrcSet =
		'https://ik.imagekit.io/59olwmt4e/tr:w-400/admissions-hero-playground.jpg 400w, https://ik.imagekit.io/59olwmt4e/tr:w-600/admissions-hero-playground.jpg 600w, https://ik.imagekit.io/59olwmt4e/tr:w-900/admissions-hero-playground.jpg 900w, https://ik.imagekit.io/59olwmt4e/admissions-hero-playground.jpg';

	const enrollmentImage = 'https://ik.imagekit.io/59olwmt4e/admissions-kid-teacher.jpg';
	const enrollmentImageSrcSet =
		'https://ik.imagekit.io/59olwmt4e/tr:w-300/admissions-kid-teacher.jpg 300w, https://ik.imagekit.io/59olwmt4e/tr:w-400/admissions-kid-teacher.jpg 400w, https://ik.imagekit.io/59olwmt4e/tr:w-500/admissions-kid-teacher.jpg 500w, https://ik.imagekit.io/59olwmt4e/tr:w-600/admissions-kid-teacher.jpg';
	return (
		<>
			<PageHeader title={pageTitle} bgImage={pageHeaderImage} bgImageSrcSet={pgHeaderImgSrcSet} />
			<Breadcrumbs page='Admissions' />

			<div className='section admission__section'>
				<div className='section__container'>
					<div className='section__header text__center'>
						<h2 className='section__title wow fadeInUp' data-wow-delay='0.5s'>
							Enrollment Procedure
						</h2>
					</div>
					<div className='section__content '>
						<img className='wow fadeInUp' src={enrollmentImage} srcSet={enrollmentImageSrcSet} alt='Enrollment' data-wow-delay='1s' />
						<div className='section__contentText wow fadeInUp' data-wow-delay='1.2s'>
							<ol>
								<li>Fill out the application form.</li>
								<li>Wait for your screening schedule.</li>
								<li>
									Complete the necessary documents and requirements.
									<br />
									<ul>
										<li>Child Information Sheet </li>

										<li>Medical Form</li>
										<li>Authorized Fetchers Form</li>
										<li>Birth Certificate (original and photocopy)</li>

										<li>Vaccination Card (updated shots)</li>
										<li>Photos </li>
										<li>
											For students who will attend face-to-face classes: <br />
											<ul>
												<li>
													Certification of health from the child’s pediatrician indicating that the child is healthy, immunization is up to date, and the child is fit to
													attend a face-to-face school setup
												</li>
											</ul>
										</li>
									</ul>
								</li>
								<li>Pay the tuition fee.</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Admissions;
