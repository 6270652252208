import React, { useEffect } from 'react';
import { Navbar, Footer } from '../../components';
import './page404.css';
import { useNavigate } from 'react-router-dom';

const Page404 = () => {
	const navigate = useNavigate();
	useEffect(() => {
		document.querySelector('.navbar').classList.add('is__sticky');
	});
	return (
		<>
			<Navbar />
			<div className='notFound__container'>
				<h1>Page Not Found</h1>
				<button className='notFound__goBackBtn' onClick={() => navigate(-1)}>
					&#10229; GO BACK
				</button>
			</div>
			<Footer />
		</>
	);
};

export default Page404;
