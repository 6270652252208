import React from 'react';
import './contact.css';
import { ContactForm } from '../../components';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import PageHeader from '../../components/pageHeader/PageHeader';
import { GrLocation } from 'react-icons/gr';
import { BiTime, BiMobileVibration } from 'react-icons/bi';

const Contact = () => {
	const pageTitle = 'Contact Us';

	const pageHeaderImage = 'https://ik.imagekit.io/59olwmt4e/contact-hero-calling.jpg';
	const pgHeaderImgSrcSet =
		'https://ik.imagekit.io/59olwmt4e/tr:w-400/contact-hero-calling.jpg 400w, https://ik.imagekit.io/59olwmt4e/tr:w-600/contact-hero-calling.jpg 600w, https://ik.imagekit.io/59olwmt4e/tr:w-900/contact-hero-calling.jpg 900w, https://ik.imagekit.io/59olwmt4e/contact-hero-calling.jpg';

	return (
		<div>
			<PageHeader title={pageTitle} bgImage={pageHeaderImage} bgImageSrcSet={pgHeaderImgSrcSet} />
			<Breadcrumbs page={pageTitle} />
			<div className='section bg-green'>
				<div className='section__container'>
					<div className='pageHeaderSubtext'>
						<h3 className=' wow fadeInUp' data-wow-delay='0.4s'>
							Need assistance? Send us a message online or reach us via the contact details below. We’d love to hear from you.
						</h3>
					</div>
				</div>
			</div>
			<div className='section'>
				<div className='section__container contact__formContainer'>
					<div>
						<div className='row'>
							<div className='col'>
								<ContactForm />
							</div>
							<div className='col'>
								<div className='contact__form wow fadeInUp' data-wow-delay='0.4s'>
									<h3 className=' wow fadeInUp' data-wow-delay='0.4s'>
										Contact Info
									</h3>
									<div className='contact__infoGroup'>
										<div className='contact__infoGroupItem wow fadeInUp' data-wow-delay='0.6s'>
											<div className='contact__infoGroupItemIcon'>
												<GrLocation />
											</div>
											<div className='contact__infoGroupItemText'>
												<div className='contact__infoGroupItemTextTitle'>Location</div>
												<div className='contact__infoGroupItemTextDetails'>76 Cordillera Street corner Agno Street, Brgy. Doña Josefa, Quezon City</div>
											</div>
										</div>
										<div className='contact__infoGroupItem wow fadeInUp' data-wow-delay='0.8s'>
											<div className='contact__infoGroupItemIcon'>
												<BiTime />
											</div>
											<div className='contact__infoGroupItemText'>
												<div className='contact__infoGroupItemTextTitle'>Opening Hours</div>
												<div className='contact__infoGroupItemTextDetails'>
													Monday – Friday <br />
													7:00 AM - 4:00 PM
												</div>
											</div>
										</div>
										<div className='contact__infoGroupItem wow fadeInUp' data-wow-delay='1s'>
											<div className='contact__infoGroupItemIcon'>
												<BiMobileVibration />
											</div>
											<div className='contact__infoGroupItemText'>
												<div className='contact__infoGroupItemTextTitle'>Contact Us</div>
												<div className='contact__infoGroupItemTextDetails'>
													+63 969 472-4048
													<br />
													<a href='mailto:#' className='link-text'>
														tcglpreschool@tzuchi.org.ph
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='mapouter'>
				<div className='gmap_canvas'>
					<iframe
						title='map'
						width='100%'
						height='305'
						id='gmap_canvas'
						src='https://maps.google.com/maps?q=76%20Cordillera%20Street%20corner%20Agno%20Street,&t=&z=13&ie=UTF8&iwloc=&output=embed&z=18'
						frameBorder='0'
						scrolling='no'
						marginHeight='0'
						marginWidth='0'></iframe>
					<a href='https://www.whatismyip-address.com/divi-discount/'>divi discount</a>
					<br />
					<a href='https://www.embedgooglemap.net'>interactive maps for websites free</a>
				</div>
			</div>
		</div>
	);
};

export default Contact;
