import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './singleNews.css';
import sanityClient from '../../client';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from '@sanity/block-content-to-react';
import { formatDateString } from '../../helper/functions';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import { useNavigate } from 'react-router-dom';

const loading = 'https://ik.imagekit.io/59olwmt4e/loading.gif';

const builder = imageUrlBuilder(sanityClient);
const urlFor = (source) => {
	return builder.image(source);
};

const SingleNews = (props) => {
	const [post, setPost] = useState(null);
	const [load, setLoad] = useState(false);
	const [images, setImages] = useState([]);
	const { slug } = useParams();

	const navigate = useNavigate();

	useEffect(() => {
		sanityClient
			.fetch(
				`*[slug.current=="${slug}"]{
													title,
													shortDescription,
													mainImage{
														asset->{
															_id,
															url
														},
														alt
													},
													images,
													publishedAt,
													body,
													"authorName":author->name
											   }
			`
			)
			.then((data) => {
				setPost(data[0]);
				setLoad(true);

				const newImages = data[0].images?.map((post) => ({
					src: urlFor(post?.asset).url(),
					alt: `${post?.desciption}   [Photo by ${post?.photoBy}]`,
					srcset: `${urlFor(post?.asset).width(400).url()} 400w, ${urlFor(post?.asset).width(700).url()} 700w, ${urlFor(post?.asset).width(800).url()} 800w, ${urlFor(post?.asset)
						.width(1000)
						.url()} 1000w`,
					sizes: '(max-width: 500px) 400px, (max-width: 1000px) 800px, (max-width: 2000px) 800px',
					// sizes: '(max-width: 500px) 400px, (max-width: 1000px) 800px, (max-width: 2000px) 700px, 1000px',
					thumbnail: `${urlFor(post?.asset).width(100).url()}`
				}));
				setImages(newImages);
			})
			.catch(console.error);
	}, [slug]);

	return (
		<>
			{!load && (
				<div className='article__loadingContainer'>
					<img src={loading} className='loadingImage' alt='Loading' />
				</div>
			)}
			{post ? (
				<div className='article__container'>
					<article>
						<button className='article__goBackBtn' onClick={() => navigate(-1)}>
							&#10229; Go Back
						</button>
						<header>
							<h1>{post?.title}</h1>
							<div className='article__subheader'>
								<span>By {post?.authorName}</span>
								<span className='noPublishedDate'>{post?.publishedAt != null ? formatDateString(post?.publishedAt) : 'No Published Date'}</span>
							</div>
						</header>
						{post?.mainImage != null && (
							<div className='article__mainImageContainer'>
								<img src={urlFor(post?.mainImage).url()} alt={post?.title} />
							</div>
						)}
						<hr />
						<div className='article__body'>
							<BlockContent blocks={post?.body} projectId='ospoftg8' dataset='production' />
						</div>
						<div className='article__gallery gallery__container'>
							{images && images.length > 0 && (
								<Carousel
									className='article__carousel'
									images={images}
									hasIndexBoard={false}
									hasMediaButton={false}
									hasCaptions='bottom'
									canAutoPlay
									isAutoPlaying
									hasSizeButton={false}
									objectFit='cover'
								/>
							)}
						</div>
					</article>
				</div>
			) : (
				load && (
					<div className='article__notFoundContainer'>
						<h2>Article Not Found</h2>
					</div>
				)
			)}
		</>
	);
};

export default SingleNews;
