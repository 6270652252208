import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Routes, Route } from 'react-router-dom';
import { Layout, Home, Page404, Admissions, Contact, Programs, About, Curriculum, SingleNews, News } from './containers';
import WOW from 'wowjs';
import { useEffect } from 'react';

const App = () => {
	useEffect(() => {
		new WOW.WOW({
			live: true
		}).init();

		if (window.location.pathname === '/') {
			//const sectionAboutUs = document.querySelector('.section__aboutUs > *');
			//sectionAboutUs.style.maxWidth = '1920px';
		}
	}, []);
	return (
		<div className='App'>
			<Routes>
				<Route path='/' element={<Layout />}>
					<Route index element={<Home />} />
					<Route path='news/article/:slug' element={<SingleNews />} />
					<Route path='news' element={<News />} />
					<Route path='about' element={<About />} />
					<Route path='curriculum' element={<Curriculum />} />
					<Route path='programs' element={<Programs />} />
					<Route path='admissions' element={<Admissions />} />
					<Route path='contact' element={<Contact />} />
				</Route>
				<Route path='*' element={<Page404 />} />
			</Routes>
		</div>
	);
};

export default App;
