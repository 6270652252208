const videoCover = 'https://ik.imagekit.io/59olwmt4e/tr:w-600/video-cover.jpg';

const programs = 'https://ik.imagekit.io/59olwmt4e/tr:w-600/home-programs.jpg';
const mind = 'https://ik.imagekit.io/59olwmt4e/tr:w-800/home-mind.jpg';
const body = 'https://ik.imagekit.io/59olwmt4e/tr:w-400/home-body.jpg';
const heart = 'https://ik.imagekit.io/59olwmt4e/tr:w-400/home-heart.jpg';

const bldg = 'https://ik.imagekit.io/59olwmt4e/tr:w-900/about-left-bldg.jpg';

const imgNotAvailable = 'https://ik.imagekit.io/59olwmt4e/tr:w-500/img-not-available.png';

const mindSrcSet =
	'https://ik.imagekit.io/59olwmt4e/tr:w-400/home-mind.jpg 400w, https://ik.imagekit.io/59olwmt4e/tr:w-600/home-mind.jpg 600w, https://ik.imagekit.io/59olwmt4e/tr:w-767/home-mind.jpg 767w';

const heartSrcSet =
	'https://ik.imagekit.io/59olwmt4e/tr:w-400/home-heart.jpg 400w, https://ik.imagekit.io/59olwmt4e/tr:w-600/home-heart.jpg 600w, https://ik.imagekit.io/59olwmt4e/tr:w-767/home-heart.jpg 767w';

const bodySrcSet =
	'https://ik.imagekit.io/59olwmt4e/tr:w-400/home-body.jpg 400w, https://ik.imagekit.io/59olwmt4e/tr:w-600/home-body.jpg 600w, https://ik.imagekit.io/59olwmt4e/tr:w-767/home-body.jpg 767w';

const programsSrcSet =
	'https://ik.imagekit.io/59olwmt4e/tr:w-300/home-programs.jpg 300w, https://ik.imagekit.io/59olwmt4e/tr:w-400/home-programs.jpg 400w, https://ik.imagekit.io/59olwmt4e/tr:w-500/home-programs.jpg 500w, https://ik.imagekit.io/59olwmt4e/tr:w-600/home-programs.jpg ';

export { mind, heart, body, mindSrcSet, heartSrcSet, bodySrcSet, programsSrcSet, programs, videoCover, bldg, imgNotAvailable };
