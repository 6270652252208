import React from 'react';
import './about.css';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import PageHeader from '../../components/pageHeader/PageHeader';
import { pageHeaderImage, pgHeaderImgSrcSet, child, vision } from './import';
import { FaChild } from 'react-icons/fa';
import { GiBabyfootPlayers, GiShakingHands } from 'react-icons/gi';
import { MdOutlineAssessment } from 'react-icons/md';
import { TbMessageLanguage, TbBuildingCommunity } from 'react-icons/tb';
import { RiEmotionHappyLine } from 'react-icons/ri';

const About = () => {
	const pageTitle = 'About Us';

	return (
		<>
			<PageHeader title={pageTitle} bgImage={pageHeaderImage} bgImageSrcSet={pgHeaderImgSrcSet} />
			<Breadcrumbs page={pageTitle} />

			<div className='section programs__section bg-gray'>
				<div className='section__container'>
					<div className='section__content align-items-top'>
						<iframe
							width='560'
							height='315'
							src='https://www.youtube.com/embed/wHG_gsKAc0Y'
							title='Tzu Chi Great Love Preschool Philippines'
							frameBorder='0'
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
							allowFullScreen></iframe>
						<div className='section__contentText wow fadeInUp' data-wow-delay='.6s'>
							<h3>
								<span>TZU CHI GREAT LOVE PRESCHOOL</span>A Progressive School for
								<br /> children ages 2-4
							</h3>
							<p>
								Our programs aim to develop young children&#39;s physical, cognitive, socio-emotional, character, and life skills and Tzu Chi’s humanistic values through experiential
								learning in a loving and nurturing environment. Our programs are designed to build and support the child’s self-confidence, self-identity, independence, and
								interdependence and hone their character traits, which include: respect or filial piety, responsibility, gratitude, compassion, contentment, and protecting the
								environment.
							</p>
							<p>
								At the end of the program, we aim to hone the child’s heart, mind, and body through active engagement in play, developmentally appropriate, and socio-culturally
								appropriate activities, and humanistic culture. While instilling a lifelong love for learning, we aim to develop 21st-century learners and equip them with the necessary
								skills needed to be responsible and productive citizens as they carry on the spirit of gratefulness, respect, and great love in the world.
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className='section programs__section '>
				<div className='section__container'>
					<div className='section__content align-items-top'>
						<img className='wow fadeInUp img-circle' src={child} alt='Enrollment' data-wow-delay='.4s' />
						<div className='section__contentText wow fadeInUp' data-wow-delay='.6s'>
							{/* <div className='circle__vector__container'>
								<div className='circle__vector'></div>
							</div> */}
							<h3>
								<span>OUR MISSION</span>Tzu Chi Great Love Preschool Philippines exists in the community to nurture the whole child by:
							</h3>
							<ul>
								<li>Providing young learners with various meaningful learning in developmentally and socio-culturally appropriate play activities</li>
								<li>Creating a responsible and caring community of learners as they carry the spirit of gratefulness, respect, and great love in the world.</li>
								<li>Prioritizing moral character values in our teachings</li>
								<li>Fostering positive approaches to learning that can support their transition to the primary years of formal education, later learning success, and achievement</li>
								<li>Anchoring the curriculum based on the principles of human development and educational theories</li>
								<li>Placing social justice, advocacy, and building an inclusive community at the core of our work</li>
								<li>Creating a safe and nurturing learning environment that is based on respect and positive regard in a diversified environment</li>
								<li>
									Establishing a collaborative partnership with teachers, parents, and the community that is deemed necessary in making decisions for the child’s best interests and
									well-being
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className='section programs__section no-pt '>
				<div className='section__container'>
					<div className='section__content '>
						<div className='section__contentText wow fadeInLeft' data-wow-delay='.4s'>
							{/* <div className='circle__vector__container'>
								<div className='circle__vector'></div>
							</div> */}
							<h3>
								<span>OUR VISION</span>Here in Tzu Chi Great Love Preschool Philippines, we will:
							</h3>
						</div>
						<img className='wow fadeInDown' src={vision} alt='vision' data-wow-delay='.6s' />
					</div>
				</div>
			</div>
			<div className='section programs__section  bg-dark-green'>
				<div className='section__container'>
					<div className='belief__section'>
						{/* <div className='circle__vector__container'>
							<div className='circle__vector'></div>
						</div> */}
						<h3 className='wow fadeInDown' data-wow-delay='.4s'>
							In Tzu Chi Great Love <br />
							Preschool, we believe that:
							<br />
						</h3>

						<div className='feature__list wow fadeInUp' data-wow-delay='.6s'>
							<div className='feature__item '>
								<div className='feature__item-icon'>
									<FaChild />
								</div>
								<div className='feature__item-text'>
									<h4>The child…</h4>

									<ul>
										<li>Is a unique individual, endowed with innate abilities, potentials, intelligence, and the natural curiosity to learn about the world</li>
										<li>Learns best through active involvement with the 1. Materials 2. Ideas and 3. People</li>
									</ul>
								</div>
							</div>
							<div className='feature__item '>
								<div className='feature__item-icon'>
									<GiBabyfootPlayers />
								</div>
								<div className='feature__item-text'>
									<h4>Learning…</h4>

									<ul>
										<li>Is done best through child-initiated and teacher-guided play and their interaction with materials, their thoughts, and with other children and adults</li>
										<li>Is best implemented when anchored between the educator&#39;s knowledge of human development and proven educational theories</li>
									</ul>
								</div>
							</div>
							<div className='feature__item '>
								<div className='feature__item-icon'>
									<MdOutlineAssessment />
								</div>
								<div className='feature__item-text'>
									<h4>Assessment…</h4>

									<ul>
										<li>Is an ongoing process and is best achieved in a supportive and authentic context</li>
										<li>Is based on the goals of the program, not by comparison with other children</li>
									</ul>
								</div>
							</div>
							<div className='feature__item '>
								<div className='feature__item-icon'>
									<TbMessageLanguage />
								</div>
								<div className='feature__item-text'>
									<h4>Environment…</h4>

									<ul>
										<li>
											Is a language-rich and multi-sensory-filled setting, complemented with competent, nurturing, responsive, and caring adults, which heighten the child’s
											curiosity and sense of wonder
										</li>
									</ul>
								</div>
							</div>

							<div className='feature__item '>
								<div className='feature__item-icon'>
									<RiEmotionHappyLine />
								</div>
								<div className='feature__item-text'>
									<h4>The Teacher…</h4>

									<ul>
										<li>
											Possesses a happy, positive disposition (i.e., open to learning, accepts constructive criticism, lifelong learner, confident, caring) and a responsive
											attitude towards the child’s individual needs, interests, and abilities
										</li>
									</ul>
								</div>
							</div>
							<div className='feature__item '>
								<div className='feature__item-icon'>
									<GiShakingHands />
								</div>
								<div className='feature__item-text'>
									<h4>The Family…</h4>

									<ul>
										<li>
											Is a valuable support to the child’s early educational experiences—thus, a strong partnership between the school and the child’s family is an essential
											ingredient
										</li>
									</ul>
								</div>
							</div>
							<div className='feature__item '>
								<div className='feature__item-icon'>
									<TbBuildingCommunity />
								</div>
								<div className='feature__item-text'>
									<h4>The Community…</h4>

									<ul>
										<li>Promotes social justice, advocacy, and building an inclusive community</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default About;
