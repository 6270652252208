import React, { useState, useEffect } from 'react';
import './news.css';
import PageHeader from '../../components/pageHeader/PageHeader';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import sanityClient from '../../client';
import imageUrlBuilder from '@sanity/image-url';
import { AiOutlineCaretLeft, AiOutlineCaretRight } from 'react-icons/ai';

const loading = 'https://ik.imagekit.io/59olwmt4e/loading.gif';
const imgNotAvailable = 'https://ik.imagekit.io/59olwmt4e/tr:w-500/img-not-available.png';

const builder = imageUrlBuilder(sanityClient);
const urlFor = (source) => {
	return builder.image(source);
};

const News = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [pageLimit, setPageLimit] = useState(12);
	const [totalCount, setTotalCount] = useState(0);
	const [posts, setPosts] = useState(null);
	const [load, setLoad] = useState(false);
	const [fetchLoading, setFetchLoading] = useState(false);
	const pageTitle = 'Latest News';
	const pageHeaderImage = 'https://ik.imagekit.io/59olwmt4e/about-hero-school.jpg';
	const pgHeaderImgSrcSet =
		'https://ik.imagekit.io/59olwmt4e/tr:w-400/about-hero-school.jpg 400w, https://ik.imagekit.io/59olwmt4e/tr:w-600/about-hero-school.jpg 600w, https://ik.imagekit.io/59olwmt4e/tr:w-900/about-hero-school.jpg 900w, https://ik.imagekit.io/59olwmt4e/about-hero-school.jpg';

	useEffect(() => {
		setFetchLoading(true);
		sanityClient
			.fetch(
				`count(*[_type=='post'] | order(publishedAt desc, _createdAt desc){
														title, 
														slug,
														author,
														shortDescription,
														mainImage{
															asset->{
																_id,
																url
															},
															alt
														},
														body,
														images,
														publishedAt,
														_createdAt
													})`
			)
			.then((data) => {
				setTotalCount(data);
				sanityClient
					.fetch(
						`*[_type=='post'] | order(publishedAt desc, _createdAt desc)[${currentPage * pageLimit - pageLimit}...${currentPage * pageLimit}]{
														title, 
														slug,
														author,
														shortDescription,
														mainImage{
															asset->{
																_id,
																url
															},
															alt
														},
														body,
														images,
														publishedAt,
														_createdAt
													}`
					)
					.then((data) => {
						setPosts(data);
						setLoad(true);
						setFetchLoading(false);
					})
					.catch(console.error);
			})
			.catch(console.error);
	}, [currentPage]);

	const nextPage = () => {
		if (!fetchLoading) {
			const maxPage = Math.ceil(totalCount / pageLimit);
			const newCurrentPage = currentPage < maxPage ? currentPage + 1 : currentPage;
			setCurrentPage(newCurrentPage);
		}
	};

	const prevPage = () => {
		if (!fetchLoading) {
			const newCurrentPage = currentPage > 1 ? currentPage - 1 : currentPage;
			setCurrentPage(newCurrentPage);
		}
	};

	return (
		<div>
			<PageHeader title={pageTitle} bgImage={pageHeaderImage} bgImageSrcSet={pgHeaderImgSrcSet} />
			<Breadcrumbs page={pageTitle} />

			{!load && (
				<div className='article__loadingContainer'>
					<img src={loading} className='loadingImage' alt='Loading' />
				</div>
			)}

			<div className='section '>
				<div className='section__container'>
					<div>
						<div className='section__header text__center'>
							<h2 className='section__title wow fadeInUp' data-wow-delay='0.2s'>
								News & Events
							</h2>
							<p className='wow fadeInDown' data-wow-delay='0.5s'>
								Latest Updates, Stories, and Events
							</p>
						</div>
						<div className='section__pagination wow fadeInDown' data-wow-delay='.8s'>
							<div className='section__paginationDisplay'>
								<p>
									Showing {totalCount > 0 ? currentPage * pageLimit - pageLimit + 1 : 0} -{' '}
									{totalCount > 0 ? (totalCount > currentPage * pageLimit ? currentPage * pageLimit : totalCount) : 0} of {totalCount}
								</p>
							</div>
							<div className='section__paginationButtons'>
								<span onClick={prevPage} className={fetchLoading || currentPage === 1 ? 'disabled' : ''}>
									<AiOutlineCaretLeft />
								</span>
								<span onClick={nextPage} className={fetchLoading || currentPage >= Math.ceil(totalCount / pageLimit) ? 'disabled' : ''}>
									<AiOutlineCaretRight />
								</span>
							</div>
						</div>
						{posts && posts?.length > 0 && (
							<div className='card__container'>
								{fetchLoading && (
									<div className='card__loading'>
										<img src={loading} alt='loading' className='loadingImage' />
									</div>
								)}
								{posts?.map((post, index) => {
									const singleNewsUrl = `/news/article/${post?.slug.current}`;

									return (
										<div className='card' key={index}>
											<div className='card__image'>
												<a href={singleNewsUrl}>
													{post?.mainImage != null ? (
														<img src={urlFor(post?.mainImage).width(500).url()} alt={post?.title} />
													) : (
														<img src={imgNotAvailable} alt='Not Available' />
													)}
												</a>
											</div>
											<div className='card__text'>
												<a href={singleNewsUrl}>
													<div className='card__title'>{post?.title}</div>
												</a>
											</div>
										</div>
									);
								})}
							</div>
						)}
						{posts?.length <= 0 && load && (
							<div className='noNewsFound__container'>
								<h2>No News Available</h2>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default News;
