import React, { useState } from 'react';
import './navbar.css';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';

const logo = 'https://ik.imagekit.io/59olwmt4e/tr:w-150/logo.png';

const Menu = () => {
	return (
		<>
			<p>
				<a href='/'>Home</a>
			</p>
			<p>
				<a href='/about'>About</a>
			</p>
			<p>
				<a href='/curriculum'>Curriculum</a>
			</p>
			<p>
				<a href='/programs'>Programs</a>
			</p>
			<p>
				<a href='/admissions'>Admissions</a>
			</p>
			<p>
				<a href='/news'>News</a>
			</p>
			<p>
				<a href='/contact'>Contact Us</a>
			</p>
		</>
	);
};

const Navbar = () => {
	const [toggleMenu, setToggleMenu] = useState(false);
	return (
		<nav className='navbar'>
			<div className='navbar__container'>
				<div className='navbar__logo'>
					<a href='/'>
						<img src={logo} alt='logo' />
						<h4 className='navbar__logo-text'>
							Tzu Chi Great Love <br />
							Preschool Philippines
						</h4>
					</a>
				</div>
				<div className='navbar__links'>
					<Menu />
				</div>
				<div className='navbar__menu'>
					{toggleMenu ? <RiCloseLine size={27} onClick={() => setToggleMenu(false)} /> : <RiMenu3Line className='navmenubtn' size={27} onClick={() => setToggleMenu(true)} />}
					{toggleMenu && (
						<div className='navbar__menu-container fade-in-top'>
							<div className='navbar__menu-links'>
								<Menu />
							</div>
						</div>
					)}
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
